import Config from '../components/Config.vue';
import FinanceAuditFilesList from '../components/FinanceAuditFilesList.vue';
import Home from '../views/HomePage.vue';
import NotFound from '../components/NotFound.vue';
import Report from '../components/Report.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import TimeReport from '../components/TimeReport.vue';
import Setups from '../components/Setups.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => {
      if (store.getters.useXsuaaSso) {
        return import(/* webpackChunkName: "home" */ '../views/HomePage.vue');
      }
      return import(/* webpackChunkName: "login" */ '../views/LoginPage.vue');
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/index.html',
    name: 'Index',
    component: () => {
      if (store.getters.useXsuaaSso) {
        return import(/* webpackChunkName: "home" */ '../views/HomePage.vue');
      }
      return import(/* webpackChunkName: "login" */ '../views/LoginPage.vue');
    },
  },
  {
    path: '/config',
    name: 'Config',
    component: Config,
  },
  {
    path: '/reports',
    name: 'Reporting',
    component: Report,
  },
  {
    path: '/timeReport',
    name: 'Time Report',
    component: TimeReport,
  },
  {
    path: '/setups',
    name: 'Setups',
    component: Setups,
  },
  {
    path: '/finance',
    name: 'Finance',
    component: FinanceAuditFilesList,
  },
  { path: '*', name: 'NotFound', component: NotFound },
];

const router = new VueRouter({
  routes: routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Login') {
    await store.dispatch('fetchEnvironmentConfig');
    if (store.state.loggedIn) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    if (store.state.loggedIn) {
      next();
    } else {
      await store.dispatch('fetchEnvironmentConfig');
      if (store.state.loggedIn) {
        next();
      } else {
        next({ name: 'Login' });
      }
    }
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = 'Reimbursement';
  });
});
