import axios from 'axios';
import store from '../store';

const instance = axios.create({});
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    /**
     * @param {Object} error
     * @param {Object} error.response
     * @param {Object} error.response.data
     * @param {number} error.response.status
     * @param {string} error.message
     * @return {string}
     */
    function errorMessages(error) {
      if (error.response) {
        if (error.response.status === 401) {
          return 'Incorrect username or password';
        }

        return (
          error.response?.data?.message ||
          error.response?.data ||
          error.response
        );
      }
    }

    await store.dispatch('displayErrorDialog', {
      component: 'MessageStrip',
      props: {
        text: errorMessages(error),
        type: 'Negative',
      },
    });
    return Promise.reject(error);
  }
);

export default {
  async executeGet(url, reload = true) {
    const config = {
      headers: {
        'X-CSRF-Token': 'Fetch',
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
      transformResponse: [
        function (data) {
          let a = null;
          try {
            a = JSON.parse(data);
          } catch (e) {
            if (reload) {
              location.reload();
            } else {
              console.error(e);
            }
          }
          return a;
        },
      ],
    };

    if (store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return axios.get(url, config).then((response) => {
      store.commit(
        'SET_CSRF_TOKEN',
        response.request.getResponseHeader('X-Csrf-Token')
      );
      return response;
    });
  },

  async executeDelete(url, authenticate = true) {
    const config = {
      transformResponse: [
        function (res) {
          if (res.length === 0) {
            res = 'null';
          }
          return JSON.parse(res);
        },
      ],
      headers: {
        'X-CSRF-Token': store.state.csrfToken,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
    };

    if (authenticate && store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return axios.delete(url, config);
  },

  async executePost(url, data, authenticate = true) {
    const config = {
      transformResponse: [
        function (res) {
          if (res.length === 0) {
            res = 'null';
          }
          return JSON.parse(res);
        },
      ],
      headers: {
        'X-CSRF-Token': store.state.csrfToken,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
    };

    if (authenticate && store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return axios.post(url, data, config);
  },

  async executePut(url, data) {
    const config = {
      transformResponse: [
        function (res) {
          if (res.length === 0) {
            res = 'null';
          }
          return JSON.parse(res);
        },
      ],
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
    };

    if (store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return instance({
      method: 'PUT',
      url: url,
      data: data,
      ...config,
    });
  },

  buildParamsUrl(params) {
    let paramUrl = '';
    for (const param in params) {
      if (param != null) {
        paramUrl += param + '=' + params[param] + '&';
      }
    }
    return paramUrl.substring(0, paramUrl.length - 1);
  },

  getTypeSelectListOptions() {
    const url = '/v1/reimbursementTypes';
    return this.executeGet(url);
  },

  getStatesSelectListOptions() {
    const url = '/v1/reimbursementStates';
    return this.executeGet(url);
  },

  getEmployees(filter) {
    const url = '/v1/employees?' + this.buildParamsUrl(filter);
    return this.executeGet(url);
  },

  getEmployeeTimeDataReport(filter) {
    const url = '/v1/employee/timeDataReport?' + this.buildParamsUrl(filter);
    return this.executeGet(url);
  },

  getEmployeeDetails(employeeId) {
    const reload = false;
    return this.executeGet('/v1/employee/' + employeeId, reload);
  },

  getConfigData() {
    return this.executeGet('/v1/config/');
  },

  getRateList() {
    return this.executeGet(`/v1/config/rates`);
  },

  postNewHourlyRate(rateValue) {
    return this.executePost(`/v1/config/rates`, {
      value: rateValue,
    });
  },
  updateHourlyRate(rate) {
    return this.executePut(`/v1/config/rates/${rate.id}`, rate);
  },
  getReimbursementCases(params) {
    const url = '/v1/reimbursements?' + this.buildParamsUrl(params);
    return this.executeGet(url);
  },

  getReimbursementCaseStates(params) {
    const url = '/v1/reimbursements/states?' + this.buildParamsUrl(params);
    return this.executeGet(url);
  },

  getReimbursementCaseStatesWithEmployee(caseId) {
    const url = `/v1/reimbursements/employee?caseId=${caseId}`;
    return this.executeGet(url);
  },

  getSchema(data) {
    const url = '/v1/reimbursement/schema';
    return this.executePost(url, data);
  },

  getConfigSchema(param) {
    return this.executePost('/v1/configSchema', param);
  },

  updateConfigValue(dataToEdit) {
    return this.executePost('/v1/updateConfig', dataToEdit);
  },

  save(formData, reimbursementCase, caseDetails) {
    const params = {
      formValues: formData,
      formType: reimbursementCase.formCode,
      formEnum: reimbursementCase.formEnum,
      caseId: reimbursementCase.id,
      caseState: reimbursementCase.state,
      caseStatus: reimbursementCase.status,
      absenceType: caseDetails?.absenceType,
      comment: reimbursementCase.comment || reimbursementCase.ownComment,
      signedOff: reimbursementCase.signedOff,
    };
    const url = '/v1/reimbursement/save';
    return this.executePost(url, params);
  },

  signAndSubmit(formData, reimbursementCase) {
    const params = {
      formValues: formData,
      formType: reimbursementCase.formCode,
      formEnum: reimbursementCase.formEnum,
      caseId: reimbursementCase.id,
      caseState: reimbursementCase.state,
      caseStatus: reimbursementCase.status,
      absenceType: reimbursementCase.caseDetails.absenceType,
      comment: reimbursementCase.comment || reimbursementCase.ownComment,
      signedOff: reimbursementCase.signedOff,
      signedOff2: formData.signedOff,
    };
    const url = '/v1/reimbursement/signAndSubmit';
    return this.executePost(url, params);
  },

  submit(formData, reimbursementCase) {
    const params = {
      formValues: formData,
      formType: reimbursementCase.formCode,
      formEnum: reimbursementCase.formEnum,
      caseId: reimbursementCase.id,
      caseState: reimbursementCase.state,
      caseStatus: reimbursementCase.status,
      absenceType: reimbursementCase.caseDetails.absenceType,
      comment: reimbursementCase.comment || reimbursementCase.ownComment,
      signedOff: reimbursementCase.signedOff,
    };
    const url = '/v1/reimbursement/submit';
    return this.executePost(url, params);
  },

  getAbsenceLastCheckedDate() {
    return this.executeGet('/v1/absenceLastCheckedDate');
  },

  getRunAsOfDate() {
    return this.executeGet('/v1/runAsOfDate');
  },

  startTest(runAsOfDate, absenceLastCheckedDate) {
    const url = '/v1/startTest';
    const params = {
      runAsOfDate: runAsOfDate,
      absenceLastCheckedDate: absenceLastCheckedDate,
    };
    return this.executePost(url, params);
  },

  closeCase(reimbursementCase) {
    const url = '/v1/closeCase';
    const params = {
      reimbursementCaseId: reimbursementCase.reimbursementCaseID,
    };
    return this.executePost(url, params);
  },

  financeFiles() {
    const url = '/v1/financeAuditFiles';
    return this.executeGet(url);
  },

  getNotifications() {
    const url = '/v1/notifications';
    return this.executeGet(url);
  },

  updateNotification(notification) {
    const url = '/v1/notification';
    return this.executePost(url, notification);
  },

  getCaseStatesReportData(filterData) {
    const url = '/v1/webReport';
    return this.executePost(url, filterData);
  },

  getActiveProfile() {
    const url = '/v1/getActiveProfile';
    return this.executeGet(url);
  },

  downloadExcelReport(filterData) {
    const url = '/v1/fullReport';
    const config = {
      transformResponse: [
        function (res) {
          return res;
        },
      ],
      headers: {
        'X-CSRF-Token': store.state.csrfToken,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
    };
    if (store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return instance({
      method: 'POST',
      url: url,
      data: filterData,
      responseType: 'blob',
      ...config,
    });
  },

  downloadTimeDataExcelReport(filter) {
    const url = '/v1/employee/timeDataExcelReport';
    const config = {
      transformResponse: [
        function (res) {
          return res;
        },
      ],
      headers: {
        'X-CSRF-Token': store.state.csrfToken,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
    };
    if (store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return instance({
      method: 'POST',
      url: url,
      data: filter,
      responseType: 'blob',
      ...config,
    });
  },

  getFormSelectListOptions() {
    const url = '/v1/getForms';
    return this.executeGet(url);
  },

  getResponseCategorySelectListOptions() {
    const url = '/v1/getResponseMessageCategories';
    return this.executeGet(url);
  },

  resetCase(reimbursementCaseID) {
    const url = '/v1/resetCase/' + reimbursementCaseID;
    return this.executeGet(url);
  },

  async login(username, password) {
    const url = '/api/auth/signin';
    const body = {
      username,
      password,
    };
    return await this.executePost(url, body, false).then(async (response) => {
      await store.dispatch('login', response.data.accessToken);
      return response;
    });
  },

  async logout() {
    const url = '/v1/signout';
    return await this.executePost(url, {}, true).then(async (response) => {
      await store.dispatch('logout');
      return response;
    });
  },

  getEnvironmentConfig() {
    const url = 'v1/environment';
    return this.executeGet(url);
  },

  uploadFile(file) {
    const url = 'v1/uploadFile';
    const config = {
      transformResponse: [
        function (res) {
          if (res.length === 0) {
            res = 'null';
          }
          return JSON.parse(res);
        },
      ],
      headers: {
        'content-type': 'multipart/form-data',
        'X-CSRF-Token': store.state.csrfToken,
        'Strict-Transport-Security':
          'max-age=31536000; includeSubDomains; preload',
      },
    };

    if (store.state.jwt) {
      config.headers['Authorization'] = `Bearer ${store.state.jwt}`;
    }

    return axios.post(url, file, config);
  },

  getRefundReasons(type) {
    const url = '/v1/refundReasons/' + type;
    return this.executeGet(url);
  },

  deleteCases(employeeId) {
    if (employeeId != null) {
      const url = '/v1/deleteCases/' + employeeId;
      return this.executeDelete(url);
    }
    return null;
  },

  getAllEmployees() {
    const url = '/v1/employees/model';
    return this.executeGet(url);
  },

  getAllBankHolidays() {
    const url = '/v1/bankHolidays';
    return this.executeGet(url);
  },

  deleteBankHoliday(bankHolidayId) {
    if (bankHolidayId != null) {
      const url = '/v1/bankHoliday/' + bankHolidayId;
      return this.executeDelete(url);
    }
    return null;
  },

  createBankHoliday(bankHoliday) {
    const url = '/v1/bankHoliday';
    return this.executePost(url, bankHoliday);
  },

  getAllTimeTypes() {
    const url = '/v1/timeTypes';
    return this.executeGet(url);
  },

  deleteTimeType(timeTypeId) {
    if (timeTypeId != null) {
      const url = '/v1/timeType/' + timeTypeId;
      return this.executeDelete(url);
    }
    return null;
  },

  createTimeType(timeType) {
    const url = '/v1/timetypes';
    return this.executePost(url, timeType);
  },

  getAllValueMappings() {
    const url = '/v1/valueMappings';
    return this.executeGet(url);
  },

  deleteValueMapping(valueMappingId) {
    if (valueMappingId != null) {
      const url = '/v1/valueMapping/' + valueMappingId;
      return this.executeDelete(url);
    }
    return null;
  },

  createValueMapping(valueMapping) {
    const url = '/v1/valueMapping';
    return this.executePost(url, valueMapping);
  },

  getValueMappingTypeSelectListOptions() {
    const url = '/v1/valueMappingTypes';
    return this.executeGet(url);
  },

  getReimbursementTypeSelectListOptions() {
    const url = '/v1/reimbursementTypesAndDescriptions';
    return this.executeGet(url);
  },
};
