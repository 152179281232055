export const dkConstants = {
  FROM_DATE_DAYS_AGO: 30,
  DEFAULT_FILTER_MONTHS_AGO: 6,
  MONTH_NUMBERS: [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ],
  DATE_FORMAT_OPTIONS: { year: 'numeric', month: 'long', day: 'numeric' },
  SHORT_MONTH_DATE_FORMAT_OPTIONS: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  DK_ENVIRONMENT_SUFFIXS: {
    PRODUCTION: 'prod',
  },
  FILTER_OPTIONS: [
    { name: 'None' },
    { name: 'Name' },
    { name: 'Type' },
    { name: 'State' },
    { name: 'ID' },
  ],
  NOTIFICATION_PAGE_SIZE: 50,
  HOURLY_RATE_DECIMAL_PLACES: 2,
  DEFAULT_TOAST_DURATION: 3000,
  DEFAULT_DEBOUNCE_DURATION: 50,
  DEFAULT_ENVIRONMENT: {
    useXsuaaSso: false,
  },
};
